﻿/*
  Mobile Nav
  By: Alex Sperellis

  Description:
  Handles the mobile navigation
*/

module.exports = (function () {

  // has class function
  function hasClass(elem, className) {
    return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
  }

  // toggle class function
  function toggleClass(elem, className) {
    var newClass = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' ';
    if (hasClass(elem, className)) {
      while (newClass.indexOf(' ' + className + ' ') >= 0) {
        newClass = newClass.replace(' ' + className + ' ', ' ');
      }
      elem.className = newClass.replace(/^\s+|\s+$/g, '');
    } else {
      elem.className += ' ' + className;
    }
  }

  // change inline svg
  const changeSvg = function changeSvg(origEl, newId) {
    $(origEl + ' use').prop('href').baseVal = newId;
  };

  // toggle for mobile nav
  function mobileNavToggle() {
    // if the nav doesnt have an open class
    if(!mobileNav.classList.contains('open') && $('.search-toggle').length > 0) {
      // close search tray
      $('.search, .search-toggle').addClass('collapsed').removeClass('open');
      // add class to the body
      $('body').removeClass('search-open');

      if(window.innerWidth < 992) {
        changeSvg('.search-toggle.hidden-lg-up .icon-search', '#icon-search');
      }
    }

    // toggle body, hamburger classes and nav classes to all open
    toggleClass(body, 'sidepanel-active');
    toggleClass(hamburgerIcon, 'open');
    toggleClass(hamburgerIcon, 'collapsed');
    toggleClass(mobileNav, 'open');
    if(secondaryMobileNav && !mobileNav.classList.contains('open')) {
      secondaryMobileNav.classList.remove('open');
    }
  }

  // close nav function - closes the nav and resets hamburger
  function closeNav() {
    body.classList.remove('sidepanel-active');
    hamburgerIcon.classList.remove('open');
    hamburgerIcon.classList.add('collapsed');
    mobileNav.classList.remove('open');
    if(secondaryMobileNav) {
      secondaryMobileNav.classList.remove('open');
    }
  }

  const body = document.querySelector('body');
  const hamburgerIcon = document.querySelector('.navbar-toggle');
  const mobileNav = document.querySelector('.navbar-sidepanel');
  const secondaryMobileNav = document.querySelector('.navbar-sidepanel-secondary');
  const secondaryNavBack = document.querySelector('.nav-link-back');
  const mask = document.querySelector('.navbar-sidepanel-mask');
  let navLinks = document.querySelectorAll('.navbar-sidepanel:not(.navbar-sidepanel-secondary) .nav-link');
  let secondaryNavLinks = [...document.querySelectorAll('.navbar-sidepanel-secondary .nav-item > .nav-link:not(.nav-link-back):not(.nav-link-title)')];
  const subNav = document.querySelector('.sub-nav');
  const subNavTitle = document.querySelector('.nav-link-title');

  // close nav on click of any nav link
  for(let i = 0; i < navLinks.length; i++) {
    if(!hasClass(navLinks[i], 'js-shop-online') && !hasClass(navLinks[i], 'nav-link-browser-back')) {
      navLinks[i].onclick = closeNav;
    }
  }

  // on spo secondary nav clicks - pull open the links and update the title. back button will clear this
  if(secondaryNavLinks.length) {
    secondaryNavLinks.forEach(link => {
      link.addEventListener('click', () => {
        const linkText = link.textContent.trim();
        const linkShortName = linkText.replace(/[^a-zA-Z\d\s:]/g, '').replace(/ /g, '-').toLowerCase();
        const listOfLinks = document.querySelector('.spo-nav').querySelector('[data-listName=\'' + linkShortName + '\']');

        subNav.classList.add('open');
        subNavTitle.innerText = link.textContent.trim();
        subNavTitle.setAttribute('href', listOfLinks.dataset.url);

        subNav.appendChild(listOfLinks.cloneNode(true));
      });
    });    
  }

  // back button for secondary nav
  if(secondaryNavBack) {
    secondaryNavBack.onclick = () => {
      if(subNav && subNav.classList.contains('open')) {
        subNav.classList.remove('open');
        subNavTitle.innerText = 'SHOP ONLINE';
        // empty the subnav html
        subNav.innerHTML = '';
      } else {
        secondaryMobileNav.classList.remove('open');
      }
    };
  }

  // on click of hamburger - toggle nav
  if(hamburgerIcon !== null) {
    hamburgerIcon.onclick = mobileNavToggle;
  }  
  // on click of mask (element created when nav is open to cover all not covered by nav) - toggle nav
  if(mask !== null) {
    mask.onclick = mobileNavToggle;
  }

  // if there is a mall nav, clone all those links, add mobile nav secondary classes and make sure they are hidden on desktop
  if ($('.mall-nav').length > 0) {
    const mallNav = $('.mall-nav .nav-item').clone().each(function () {
      const origText = $(this).children('.nav-link').text();
      let capText = origText.charAt(0).toUpperCase() + origText.toLowerCase().slice(1);
      capText = capText.replace('faq', 'FAQ').replace('Faq', 'FAQ').replace('Vip', 'VIP').replace('vip', 'VIP');
      $(this).addClass('hidden-lg-up');
      $(this).children('.nav-link').addClass('nav-link-secondary').text(capText);
    });
    // if one of the nav links has js-nav-top class then add mall level links to mobile nav after that one
    if ($('.js-nav-top').length > 0) {
      mallNav.insertAfter('.navbar-sidepanel .js-nav-top');
    } else {
      // else add to the top of the mobile nav
      $('.navbar-sidepanel').prepend(mallNav);
    }

    // adaptive reg mall nav
    if($('.mall-nav.primary').length > 0) {
      const container = document.querySelector('.tabs');
      const primary = container.querySelector('.primary');
      const primaryItems = [...container.querySelectorAll('.primary > li:not(.more)')].sort((a, b) => {
        return (parseInt(b.getAttribute('data-dropdown-priority'), 10) - parseInt(a.getAttribute('data-dropdown-priority'), 10))
      });

      container.classList.add('jsfied');

      // insert 'more' button and duplicate the list
      primary.insertAdjacentHTML(
        'beforeend',
        `<li class='more nav-item'>
        <button type='button' class='nav-link' aria-haspopup='true' aria-expanded='false' title='See Other Available Options'>
          MORE <span>&darr;</span>
        </button>
        <ul class='secondary list-unstyled'>
          ${primaryItems.map(el => el.outerHTML).join('')}
        </ul>
      </li>`
      );

      const secondary = container.querySelector('.secondary');
      const secondaryItems = [...secondary.querySelectorAll('li')].sort((a, b) => {
        return (parseInt(b.getAttribute('data-dropdown-priority'), 10) - parseInt(a.getAttribute('data-dropdown-priority'), 10))
      });;
      const allItems = [...container.querySelectorAll('li')].sort((a, b) => {
        return (parseInt(b.getAttribute('data-dropdown-priority'), 10) - parseInt(a.getAttribute('data-dropdown-priority'), 10))
      });;
      const moreLi = primary.querySelector('.more');
      const moreBtn = moreLi.querySelector('button');

      moreBtn.addEventListener('click', e => {
        e.preventDefault();
        container.classList.toggle('show-secondary');
        moreBtn.setAttribute(
          'aria-expanded',
          container.classList.contains('show-secondary')
        );
      });

      // adapt tabs
      const doAdapt = () => {
        // reveal all items for the calculation
        allItems.forEach(item => {
          item.classList.remove('hidden-xs-up');
        });

        // hide items that won't fit in the Primary
        let stopWidth = moreBtn.offsetWidth + 33;
        let hiddenItems = [];
        const primaryWidth = container.offsetWidth;
        primaryItems.forEach((item, i) => {
          if(primaryWidth >= stopWidth + item.offsetWidth) {
            stopWidth += item.offsetWidth + 33;
          } else {
            item.classList.add('hidden-xs-up');
            hiddenItems.push(i);
          }
        });

        // toggle the visibility of More button and items in Secondary
        if(!hiddenItems.length) {
          moreLi.classList.add('hidden-xs-up');
          container.classList.remove('show-secondary');
          moreBtn.setAttribute('aria-expanded', false);
        } else {
          secondaryItems.forEach((item, i) => {
            if(hiddenItems.indexOf(i) < 0) {
              item.classList.add('hidden-xs-up');
            }
          });
        }
      };

      doAdapt(); // adapt immediately on load
      window.addEventListener('resize', doAdapt); // adapt on window resize

      // hide Secondary on the outside click
      document.addEventListener('click', e => {
        let el = e.target;
        while(el) {
          if(el === secondary || el === moreBtn) {
            return;
          }
          el = el.parentNode;
        }
        container.classList.remove('show-secondary');
        moreBtn.setAttribute('aria-expanded', false);
      });
    }
  }
});

