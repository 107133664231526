﻿/*
  Mobile Nav and Dropdown Animations
  By: Alex Sperellis

  Description:
  Slide in and down animations for the mobile nav on hamburger click and dropdowns on hover or click depending on the classes set to the toggle.
*/

module.exports = (function () {
  // for each dropdown toggle
  $('.dropdown-toggle').each(function () {
    const $dropdownToggle = $(this);
    const $dropdownMenu = $('.dropdown-menu');
    // hides the dropdown
    const hideDropdown = function () {
      $dropdownToggle.removeClass("open").next().removeClass("open");
    };
    // shows the dropdown
    const showDropdown = function () {
      $dropdownToggle.addClass("open").next().addClass("open");
    };

    // if has hover class
    if($dropdownToggle.hasClass('js-on-hover')) {
      $dropdownToggle.hover(showDropdown); // show on hover
      $dropdownMenu.mouseenter(showDropdown); // show on mouseenter
      $dropdownToggle.mouseleave(hideDropdown); // hide toggle on mouseleave
      $dropdownMenu.mouseleave(hideDropdown); // hide dropdown content on mouseleave
    } else {
      // attach click event to dropdown toggle
      $dropdownToggle.click(function (e) {
        // toggle open class on the toggle and the content which is always the next element
        $dropdownToggle.toggleClass("open").next().toggleClass("open");
      });
    }
  });
});

