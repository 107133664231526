﻿const API = require('./api');
const cookieTools = require('./cookies');

// google captcha v3
const linkAppender = () => {
  const getToken = (name) => {
    if(cookieTools.read(name)) {
      return JSON.parse(cookieTools.read(name)).token;
    }

    return false;
  };

  const token = getToken('VipAuth');
  const vipUser = JSON.parse(window.sessionStorage.getItem('vipUser'));

  // appends text to a given url string
  const appendToLinks = (appendText, url) => {
    const links = document.querySelectorAll('a[href^=\'' + url + '\']');
    for(let i = 0; i < links.length; i++) {
      const link = links[i];
      const linkQueryParams = link.getAttribute('href').split('?')[1] || '';
      const linkURL = link.getAttribute('href').split('?')[0] || link.getAttribute('href');
      link.setAttribute('href', `${linkURL}?uid=${appendText}&${linkQueryParams}`);
    }
  };

  // if a user token is stored then a user is logged in
  if(token) {
    // if we have the user data temp saved already
    if(vipUser) {
      // append the id to all spo links
      appendToLinks(vipUser.UniqueID, 'https://www.shoppremiumoutlets.com');
    } else {
      // else get the user info, save in session and append to all spo links
      API.get(window.apiUrl + '/vip/user/profile', 'Bearer ' + token, true)
        .then(function (data) {
          window.sessionStorage.setItem('vipUser', JSON.stringify(data.VipUser));
          appendToLinks(data.VipUser.UniqueID, 'https://www.shoppremiumoutlets.com');
        }).catch(function () { });
    }
  }
};

module.exports = linkAppender;
