﻿const FontFaceObserver = require('fontfaceobserver/fontfaceobserver');
const cpBook = new FontFaceObserver('SimonCircularPro-Book');
const cpBold = new FontFaceObserver('SimonCircularPro-Bold');
const cpLight = new FontFaceObserver('SimonCircularPro-Light');

(function(){
  if(sessionStorage.foutFontsLoaded) {
    document.documentElement.className += " fonts-loaded";
    // document.documentElement.className += " fonts-stage-1 fonts-stage-2";
    return;
  }

  Promise.all([cpBook.load(), cpBold.load()]).then(function () {
    document.documentElement.className += " fonts-loaded";
    sessionStorage.foutFontsLoaded = true;
   });
})();

