﻿module.exports.open = openSidenav;
module.exports.close = closeSidenav;
function openSidenav() {
    document.getElementById("globalSidenav").style.left = "0";
    document.getElementById("sideNavOverlay").style.opacity = 1;
    document.getElementById("sideNavOverlay").style.display = "block";

    //document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    //document.body.style.transition = "background-color 0.5s";
    document.body.classList.remove('search-open');
    closeByClass("search");
    closeByClass("search-toggle");
    closeByClass("search-links");
    closeByClass("search-results");
    if (window.innerWidth < 992) {
        changeSvg('.search-toggle.hidden-lg-up .icon-search', '#icon-search');
    }
}

function closeSidenav() {
    document.getElementById("globalSidenav").style.left = "-9999px";
    //document.body.style.backgroundColor = "#fff";
    document.getElementById("sideNavOverlay").style.opacity = 0;
    setTimeout(() => { document.getElementById("sideNavOverlay").style.display = "none" },200);
}

function closeByClass(c) {
    const className = document.getElementsByClassName(c);
    if (className) {
        //className.forEach(element => {
        //    element.classList.remove("open");
        //    element.classList.add("collapsed");
        //});
        Array.prototype.forEach.call(className, function (el) {
            el.classList.remove("open");
            el.classList.add("collapsed");
        });
    }
}

const changeSvg = function changeSvg(origEl, newId) {
    $(origEl + ' use').prop('href').baseVal = newId;
};