﻿/*
  cookies.js
  Tools for cookies
*/

module.exports = cookieParser;
module.exports.read = readCookie;
module.exports.write = writeCookie;

function cookieParser() {
}

// reads a cookie
function readCookie(name) {
  const c = document.cookie.split('; ');
  let cookies = {};

  for(let i = c.length - 1; i >= 0; i--) {
    const C = c[i].split('=');
    cookies[C[0]] = C[1];
  }

  return cookies[name];
}

// writes a cookie
function writeCookie(name,value,days) {

  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
    var expires = "; expires="+date.toGMTString();
  }
  else var expires = "";
  document.cookie = name+"="+value+expires+"; path=/";
}
