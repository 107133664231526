﻿// site dropdown handler. declared globally for header dropdown in PO pages
const dropdowns = require('./dropdowns');

// if po site
if (document.location.href.indexOf('premiumoutlets') >= 0) {
    const logoutBtn = document.getElementById('ddLogout');
    if (logoutBtn) {
        logoutBtn.addEventListener('click', () => {
            window.sessionStorage.removeItem('vipUser');
            window.location.href = '/vip/logout';
        });
    }

    dropdowns();
    // spo global stuff
    const spoNavToggle = document.querySelector('.spo-nav-toggle');
    const spoNav = document.querySelector('.spo-nav');
    const spoMobileNavBtn = document.querySelector('.js-shop-online');
    const secondaryMobileNav = document.querySelector('.navbar-sidepanel-secondary');

    // 1. render a div with the main cats with a back btn
    // 2. third tier is all that cats links with a back btn
    // a nav always has back btn, title and links that can be buttons or links
    if (spoMobileNavBtn) {
        spoMobileNavBtn.addEventListener('click', (event) => {
            event.preventDefault();
            secondaryMobileNav.classList.add('open');
        });
    }

    const closeNav = () => {
        spoNav.classList.remove('open');
        spoNavToggle.classList.remove('active');
    };

    const openNav = () => {
        spoNav.classList.add('open');
        spoNavToggle.classList.add('active');
    };

    if (spoNavToggle) {
        spoNavToggle.addEventListener('mouseover', openNav);
        spoNavToggle.addEventListener('mouseleave', closeNav);
    }

    if (spoNav) {
        spoNav.addEventListener('mouseover', openNav);
        spoNav.addEventListener('mouseleave', closeNav);
    }
}